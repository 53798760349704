import { Controller } from "@hotwired/stimulus";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static targets = ["share", "shareParent", "passwordInput", "passwordInputContainer",
    "buttonGroup", "openPasswordOptionsButton", "magnifyContainer", "openMagnifyButton",
    "openShareButton","hideContainer", "hideSpan"];
  static values = { url: String };
  connect() {
    this.password = ""
  }
  disconnect() {
    clearInterval(this.downloadTimer);
  }
  reveal () {
    var that = this;
    fetch(this.urlValue)
      .then((resp) => resp.json())
      .then(data => {
        this.buttonGroupTarget.style.display = "none";
        this.passwordInputContainerTarget.style.display = "block";
        this.hideContainerTarget.style.display = "block";
        this.openMagnifyButtonTarget.style.display = "flex";
        this.passwordInputTarget.value = data.password;
        this.password = data.password;


        that.timeleft = 45;
        that.downloadTimer = setInterval(function(){
          if(that.timeleft <= 0){
            that.hide()
          }
          that.hideSpanTarget.innerText = that.timeleft - 1;
          that.timeleft -= 1;
        }, 1000);

        if (that.openPasswordOptionsButtonTarget) {
          that.openPasswordOptionsButtonTarget.style.display = "flex";
          if (this.hasOpenShareButtonTarget) {
            this.openShareButtonTarget.style.display = "flex";
          }
        }
        flash("Revealed", "success")
      })
      .catch(function(error) {
        flash(error, "error")
      });
  }
  hide () {
    clearInterval(this.downloadTimer);
    this.timeleft = 45;
    this.hideSpanTarget.innerText = 45;
    this.buttonGroupTarget.style.display = "flex";
    this.passwordInputContainerTarget.style.display = "none";
    this.hideContainerTarget.style.display = "none";

    if (this.hasOpenShareTarget) {
      this.shareTarget.style.display = "none";
      this.shareParentTarget.style.display = "none";
      this.openShareButtonTarget.style.display = "none";
    }
    this.openMagnifyButtonTarget.style.display = "none";
    this.magnifyContainerTarget.style.display = "none";

  }
  magnify () {
    var that = this;
    let result = '';
    let h =  {
      "A": "ALPHA",  "B": "BRAVO",   "C": "CHARLIE",
      "D": "DELTA",  "E": "ECHO",    "F": "FOXTROT",
      "G": "GOLF",   "H": "HOTEL",   "I": "INDIA",
      "J": "JULIETT","K": "KILO",    "L": "LIMA",
      "M": "MIKE",   "N": "NOVEMBER","O": "OSCAR",
      "P": "PAPA",   "Q": "QUEBEC",  "R": "ROMEO",
      "S": "SIERRA", "T": "TANGO",   "U": "UNIFORM",
      "V": "VICTOR", "W": "WHISKEY", "X": "X-RAY",
      "Y": "YANKEE", "Z": "ZULU", "0": "Zero",
      "a": "alpha",  "b": "bravo",   "c": "charlie",
      "d": "delta",  "e": "echo",    "f": "foxtrot",
      "g": "golf",   "h": "hotel",   "i": "india",
      "j": "juliett","k": "kilo",    "l": "lima",
      "m": "mike",   "n": "november","o": "oscar",
      "p": "papa",   "q": "quebec",  "r": "romeo",
      "s": "sierra", "t": "tango",   "u": "Uniform",
      "v": "victor", "w": "whiskey", "x": "x-ray",
      "y": "yankee", "z": "zulu", "0": "Zero",
      "1": "One", "2": "Two", "3": "Three",
      "4": "Four", "5": "Five", "6": "Six",
      "7": "Seven", "8": "Eight", "9": "Nine"
    }
    for (let character of that.password) {
      if (/^\d+$/.test(character)) {
        // it is a number
        result += `<div class='lovely-item lovely--number'><span>${character}</span><p>${h[character]}</p></div>`;
      } else if (character === " "){
        // it is a space
        result += `<div class='lovely-item'><span>&nbsp;</span><p>SPACE</p></div>`;
      } else if (character.match(/[A-Z|a-z]/i)){
        // it is a letter
        result += `<div class='lovely-item'><span>${character}</span><p>${h[character]}</p></div>`;
      } else {
        // it is a special character or something weeeeeiiiiirrdddd.
        result += `<div class='lovely-item lovely--special'><span>${character}</span><p>&nbsp;</p></div>`;
      }
    }
    this.openMagnifyButtonTarget.style.display = "none";
    this.magnifyContainerTarget.style.display = "flex";
    this.magnifyContainerTarget.innerHTML = result;
  }
  toggleShare () {
    if (this.shareTarget.style.display === "block") {
      this.shareTarget.style.display = "none";
      return;
    }

    this.shareTarget.style.display = "block";

    if (this.shareParentTarget.style.display === "none") {
      this.shareParentTarget.style.display = "block";
    }
  }
}
