import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contents", "tocBlank", "tocContainer"]

  connect() {
    this.isShown = true; // Default value
    this.toc = [];
    this.getContents();
  }

  getContents() {
    const headers = this.contentsTarget.querySelectorAll("h1, h2, h3, h4, h5, h6");
    headers.forEach((element) => {
      const readable = element.textContent.trim();
      if (!readable) return;

      const tag = element.tagName;
      let id = readable.replace(/\s+/g, '-') + Math.floor(Math.random() * 5);
      element.id = id;
      this.toc.push({ tag: tag, name: readable, id: id });
    });
    this.renderToc();
  }

  renderToc() {
    if (this.toc.length == 0) {
      this.tocBlankTarget.style.display = 'block';
    } else {
      this.tocBlankTarget.style.display = 'none';
      const ul = document.createElement('ul');
      this.toc.forEach(t => {
        const li = document.createElement('li');
        li.className = `toc${t.tag}`;
        const a = document.createElement('a');
        a.href = `#${t.id}`;
        a.textContent = t.name;
        li.appendChild(a);
        ul.appendChild(li);
      });
      this.tocContainerTarget.appendChild(ul);
    }
  }
}
